import { Nullable } from '../typescript';
import { AdminFeatureFlags, UserTimeInterval, UserTypesInterface } from '../user';
import { InsightGroupConfiguration } from './insight-group-configuration.type';

export enum ChartSource {
	MIXPANEL = 'MIXPANEL',
	API = 'API',
}

export enum StatisticId {
	PLACEHOLDER = 'PLACEHOLDER', // For charts that don't have a call function yet
	PROFESSIONAL_STATS = 'PROFESSIONAL_STATS',
	COUNTRY_STATS = 'COUNTRY_STATS',
	PLATFORM_STATS = 'PLATFORM_STATS',
	RUNNER_STATS = 'RUNNER_STATS',
	HEARING_STATS = 'HEARING_STATS',
	OS_STATS = 'OS_STATS',
	SUMMARY_STATS = 'SUMMARY_STATS',
	USER_TYPE_STATS = 'USER_TYPE_STATS',
	SUMMARY_GRAPH_STATS = 'SUMMARY_GRAPH_STATS',
	TOTAL_GRAPH_STATS = 'TOTAL_GRAPH_STATS',
	SIGNUP_STATS = 'SIGNUP_STATS',
	GENDER_STATS = 'GENDER_STATS',
	AGE_STATS = 'AGE_STATS',
	INTERESTS_STATS = 'INTERESTS_STATS',
	INTERESTS_PROFESSION_STATS = 'INTERESTS_PROFESSION_STATS',
	PROFESSIONS_INTEREST_STATS = 'PROFESSIONS_INTEREST_STATS',
	NATIONALITY_STATS = 'NATIONALITY_STATS',
	NODE_COUNT = 'NODE_COUNT',
	USER_NODE_COUNT = 'USER_NODE_COUNT',
	UPTIME_STATS = 'UPTIME_STATS',
	USER_TYPE_DAILY_COUNT = 'USER_TYPE_DAILY_COUNT',
	EMAIL_RECEIVERS_COUNT = 'EMAIL_RECEIVERS_COUNT',
	// NEW STATS //
	TAG_TYPE_STATS = 'TAG_TYPE_STATS',
	ALBUM_ITEM_COUNT_STATS = 'ALBUM_ITEM_COUNT_STATS',
	FILE_TYPE_STATS = 'FILE_TYPE_STATS',
	NODE_SOURCE_STATS = 'NODE_SOURCE_STATS',
	USED_INTEGRATIONS_STATS = 'USED_INTEGRATIONS_STATS',
	PROPERTY_NAME_STATS = 'PROPERTY_NAME_STATS',
	DOMAIN_STATS = 'DOMAIN_STATS',
	TAG_NAME_STATS = 'TAG_NAME_STATS',
}

export enum DashboardChartTypes {
	INFO_CARD = 0,
	LINE = 1,
	BAR_VERTICAL = 2,
	BAR_HORIZONTAL = 3,
	PIE = 4,
	POLAR = 5,
	BINARY_BAR = 6,
	PROGRESS_BAR = 7,
	TABLE = 8,
	WIP = 9, // for api charts not yet available (requestFunction === '')
	ERROR = 10, // for api charts whose request generates an error
	BAR_VERTICAL_STACKED = 11,
}

export type TableColumn = {
	name: string;
	caption: string;
	width?: number;
	type?: TableColumnType;
	booleanTrueLabel?: string;
	booleanFalseLabel?: string;
};

export enum TableColumnType {
	STRING,
	NUMBER,
	PERCENT,
	DATE,
	BOOLEAN,
	WEB_CAPTION,
}

export interface InsightGroupApiMetadata {
	statisticId: StatisticId;
	parameters: Nullable<Omit<UserTypesInterface, 'time'> & Partial<MonitorIdParam>>;
	timeFrames: Nullable<UserTimeInterval[]>;
	apiStatisticType: ApiStatisticType;
	columns: Nullable<TableColumn[]>; // Only for tables
	goal: Nullable<number>; // Only for progress bars
	bonus: Nullable<number>; // Only for progress bars
	showAnimation: Nullable<boolean>; // Only for progress bars
}

export type MonitorIdParam = {
	monitorId: number;
};

export enum AxisType {
	CATEGORICAL = 'categorical',
	NUMERICAL = 'numerical',
	TIME = 'time',
}

export type CommonChartOptions = {
	width?: number;
	height?: number;
	axisType?: AxisType;
	valueUnit?: string;
	sameEventHumanMap?: { [key: string]: string };
	uniqueTimeFrame?: boolean;
	// configurable?: boolean;
};

export enum ApiStatisticType {
	TIME_SERIES = 'TIME_SERIES',
	TABLE = 'TABLE',
}

export interface InsightGroup<T extends CommonChartOptions> {
	insightGroupId: number;
	name: string;
	type: DashboardChartTypes;
	chartSource: ChartSource;
	lastFetched: number;
	minimumFeatureFlag: AdminFeatureFlags;
	options: Nullable<T>;
	apiMetadata: Nullable<InsightGroupApiMetadata>;
	configurations: InsightGroupConfiguration[];
}
