import { NodeType } from '../nodes';
import { SearchNodeResponse } from '../search';
import { Nullable } from '../typescript';

export enum ToolEnum {
	SEMANTIC_RETRIEVER = 'SEMANTIC_RETRIEVER',
	NLP_RETRIEVER = 'NLP_RETRIEVER',
	CUSTOM_API_CALL = 'CUSTOM_API_CALL',
	DOCUMENT_RETRIEVER = 'DOCUMENT_RETRIEVER',
}

export enum ChatCustomAPICallToolEnum {
	SHOPIFY_ORDER_RETRIEVAL = 'SHOPIFY_ORDER_RETRIEVAL',
	SHOPIFY_USER_RETRIEVAL = 'SHOPIFY_USER_RETRIEVAL',
}

type ToolInputWithReason<T extends Record<string, unknown>> = T & { executionReason?: string };

export type ChatSimilaritySearchToolInputType = ToolInputWithReason<{
	query: string;
	nodeIds: Nullable<Array<string>>;
}>;

export type ChatDocumentRetrieverToolInputType = ToolInputWithReason<{
	nodeId: string;
}>;

export type ChatCustomAPICallToolInputType = ToolInputWithReason<{
	params?: Record<string, unknown>;
	body?: Record<string, unknown>;
}>;

export type ChatCustomAPICallToolOutputItemType = ChatToolOutputItemBaseInterface & {
	item: unknown;
};

export type ChatCustomAPICallToolOutputType =
	ChatItemizedToolOutputBaseInterface<ChatCustomAPICallToolOutputItemType> & {
		count: number;
	};

export interface ChatToolOutputItemBaseInterface {
	isUsed: boolean;
}

export interface ChatItemizedToolOutputBaseInterface<T extends ChatToolOutputItemBaseInterface> {
	items: Array<T>;
}

export interface NodeChunkContentInterface {
	embeddingId: string;
	text: string;
	num_page: number;
	superblock_id: string;
	position: number;
	bbox_x0: number;
	bbox_y0: number;
	bbox_x1: number;
	bbox_y1: number;
}
export interface ChatNodesToolOutputItemBaseInterface {
	isUsed: boolean;
}
export type ChatSimilaritySearchToolOutputItemType = ChatNodesToolOutputItemBaseInterface &
	NodeChunkContentInterface & {
		node: SearchNodeResponse;
	};

export type ChatDocumentRetrieverToolOutputItemType = ChatNodesToolOutputItemBaseInterface &
	NodeChunkContentInterface & {
		node: SearchNodeResponse;
	};

export type ChatSimilaritySearchToolOutputType =
	ChatItemizedToolOutputBaseInterface<ChatSimilaritySearchToolOutputItemType>;

export type ChatDocumentRetrieverToolOutputType =
	ChatItemizedToolOutputBaseInterface<ChatDocumentRetrieverToolOutputItemType>;

export type ChatNLPSearchToolOutputItemType = ChatToolOutputItemBaseInterface & {
	node: SearchNodeResponse;
};
export type ChatNLPSearchToolOutputType =
	ChatItemizedToolOutputBaseInterface<ChatNLPSearchToolOutputItemType> & {
		nodeCount: number;
	};

interface DatePropertyFilter {
	dateProperty: string;
	dateRange: {
		start: string;
		end: string;
	};
}
interface TextualPropertyFilter {
	textualProperty: string;
	operator:
		| 'EQUALS'
		| 'NOT_EQUALS'
		| 'CONTAINS'
		| 'NOT_CONTAINS'
		| 'HAS_PROPERTY'
		| 'NOT_HAS_PROPERTY';
	value?: string;
}

interface NumericPropertyFilter {
	numericProperty: string;
	operator:
		| 'EQUALS'
		| 'NOT_EQUALS'
		| 'GREATER_THAN'
		| 'GREATER_THAN_OR_EQUAL'
		| 'LESS_THAN'
		| 'LESS_THAN_OR_EQUAL'
		| 'HAS_PROPERTY'
		| 'NOT_HAS_PROPERTY';
	value?: number;
}
export type ChatNLPSearchToolInputType = ToolInputWithReason<{
	query?: string;
	nodeTypes?: Exclude<NodeType, NodeType.WORKSPACE>[];
	datePropertyFilters?: DatePropertyFilter[];
	textualPropertyFilters?: TextualPropertyFilter[];
	numericPropertyFilters?: NumericPropertyFilter[];
}>;

export type ChatToolTypesMap = {
	[ToolEnum.SEMANTIC_RETRIEVER]: {
		input: ChatSimilaritySearchToolInputType;
		output: ChatSimilaritySearchToolOutputType;
	};
	[ToolEnum.NLP_RETRIEVER]: {
		input: ChatNLPSearchToolInputType;
		output: ChatNLPSearchToolOutputType;
	};
	[ToolEnum.CUSTOM_API_CALL]: {
		input: ChatCustomAPICallToolInputType;
		output: ChatCustomAPICallToolOutputType;
	};
	[ToolEnum.DOCUMENT_RETRIEVER]: {
		input: ChatDocumentRetrieverToolInputType;
		output: ChatDocumentRetrieverToolOutputType;
	};
};

export interface ChatToolUsageInterface<T extends ToolEnum> {
	toolType: T;
	input: ChatToolTypesMap[T]['input'];
	output: Nullable<ChatToolTypesMap[T]['output']>;
	customAPICallTool: T extends ToolEnum.CUSTOM_API_CALL ? ChatCustomAPICallToolEnum : null;
	timestamp: number;
}
