import { AppError } from './app-error';
import { StatusCodes } from 'http-status-codes';
import { ErrorCodes } from '@reach/interfaces';
import { AppErrorParams } from './app-error-params.type';

export class UnknownError extends AppError {
	constructor() {
		super({
			status: StatusCodes.INTERNAL_SERVER_ERROR,
			code: ErrorCodes.MISC_UNKNOWN_ERROR,
			title: 'Unknown Error.',
		});
	}

	public override toPayload(): AppErrorParams {
		return super.toPayload();
	}
}
