import { ChatMessageInterface } from '../chat';
import { Nullable } from '../typescript';
import { ChatWidgetSystemPayloadInterface } from './chat-widget-system-action.types';
import { ChatWidgetSystemTriggerEnum } from './chat-widget-system-trigger.enum';

export interface ChatWidgetMessageInterface extends ChatMessageInterface {
	widgetId: string;
	sessionId: string;
	hasContactedSupport: boolean;
	categories: string[];
	originUrl: Nullable<string>;
	flagId: Nullable<string>;
	systemResponse?: ChatWidgetSystemResponseInterface;
}

export interface ChatWidgetSystemResponseInterface {
	trigger: ChatWidgetSystemTriggerEnum;
	content: string;
	category?: string;
	payload?: ChatWidgetSystemPayloadInterface;
}

export type ChatWidgetBaseMessageInterface = Omit<
	ChatWidgetMessageInterface,
	| 'categories'
	| 'originUrl'
	| 'hasContactedSupport'
	| 'deleted'
	| 'userMemory'
	| 'response.status'
	| 'response.toolsUsed'
	| 'response.content'
>;
