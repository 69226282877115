import { Injectable } from '@angular/core';
import { UpdateLogsGetResponse } from '@reach/interfaces';
import { BaseApiService } from '~app-client/api/_internal';

@Injectable({ providedIn: 'root' })
export class UpdateLogsApiService extends BaseApiService {
	protected commonPath = ['update-logs'];

	public getUpdateLogs(): Promise<UpdateLogsGetResponse> {
		return this.http.get<UpdateLogsGetResponse>(this.url('')).then((groups) =>
			groups.map((g) => ({
				...g,
				slides: g.slides.sort((a, b) => a.index - b.index),
			}))
		);
	}

    public getAllUpdateLog(): Promise<UpdateLogsGetResponse> {
        return this.http.get<UpdateLogsGetResponse>(this.url('all'));
    }

	public markAsSeen(): Promise<void> {
		return this.http.post<void>(this.url('seen'));
	}
}
